@import "variables.less";

/*
01. GENERAL STYLING
02. LOGIN
03. HEADER & MENU
04. DASHBOARD
05: CONTACT PAGE
06. FOOTER
07. ANIMATION & EFFECTS
08. MESSAGING
09. REPORTS & STATEMENTS
10. SIDEBAR NAVIGATION FOR MOBILE
11. MODAL WINDOWS
12. SCHEDULED MAINTENANCE
13. INSPECTIONS
-- MEDIA QUERIES --
*/

/*-----------------------------------------------------------------------------------*/
/*	01: GENERAL STYLING 
/*-----------------------------------------------------------------------------------*/

/* app css stylesheet */
/* Base structure */
.device-sm {
    display: none;
}

.device-md {
    display: block;
}

.device-xs {
    display: none;
}

.device-lg {
    display: block;
}

html,
body {
    min-height: 100vh;
    background-color: @brand-grey4;
}

* {
    font-family: 'Open Sans';
    text-decoration: none;
    outline: none !important;
}

*:focus {
    outline: none !important;
}

.form-control {
    display: inline;
    width: 100%;
    height: 40px;
    padding: 6px 40px 6px 12px;
    font-size: 16px;
    line-height: 1.42857143;
    background-color: #fff;
    border: 1px solid @brand-grey3;
    border-radius: 0px;
}

.form-group {
    margin-bottom: 20px;
}

.form-control:focus {
    box-shadow: none;
    transition: none;
}

.form-dd {
    border-right: none;
    padding: 6px 12px;
}

textarea {
    font-weight: 700;
    font-size: 16px;
    background-color: @brand-grey4;

    &:focus {
        border: 2px solid #333;
    }
}

input[type="text"] {
    height: 40px;
    font-size: 16px;
    padding: 0 15px;
    background-color: @brand-grey4;
}

input:focus {
    border: 2px solid #333;
}

input[type="checkbox"] {
    opacity: 0;
    position: absolute;
}

input[type="checkbox"] + label {
    text-align: center;
    cursor: pointer;
}

input[type="checkbox"]:focus + label {
    background-color: #fff;
}

input[type="checkbox"] + label div {
    display: inline-block;
    line-height: 20px;
    font-size: 20px;
    height: 30px;
    width: 30px;
    margin: -0px 4px 0 0;
    border: 4px solid @brand-grey3;
    color: transparent;
}

input[type="checkbox"]:checked + label div {
    color: @brand-blue;
}

.inline-block {
    display: inline-block;
}

.btn .caret {
    position: relative;
    top: 0px;
    display: inline-block;
    font-family: 'Glyphicons Halflings';
    font-style: normal;
    font-size: 18px;
    font-weight: 400;
    line-height: 1;

    &:before {
        content: "\e114";
    }

    border: 0;
    border-left: 1px solid @brand-grey3;
    width: 15px;
    height: 25px;
    padding: 5px 4px 4px 12px;
    left: -12px;
}

.error-text {
    color: @brand-danger;
    font-weight: 700;
}

.scroller {
    height: 300px;
    overflow-y: hidden;
    position: relative;
}

.ps-container .ps-scrollbar-y-rail {
    display: inherit;
    opacity: 1 !important;
    filter: alpha(opacity=1) !important;
}

.ps-container .ps-scrollbar-y {
    background-color: @brand-blue !important;
}

.dropdown-style {
    color: @brand-black;
    border: 1px solid @brand-grey3;
    padding: 7px 6px 6px 6px;
    display: table;
    font-size: 18px;
    float: left;
    width: 100%;
    background-color: @brand-grey4;

    h2 {
        display: table-cell;
        vertical-align: top;
    }

    i {
        display: table-cell;
        vertical-align: middle;
        padding: 0 5px 0 13px;
        border-left: 1px solid @brand-grey3;
        margin-left: 10px;
        width: 25px;
    }

    span {
        margin-right: 25px;
        display: block;
    }
}

.social-account {
    box-sizing: border-box;
    height: 60px;
    padding: 15px;
    color: white;
    border: 1px solid white;
    font-size: 18px;
    width: 160px;
    word-wrap: break-word;

    > a {
        color: white;
    }
}

.facebook-account {
    background: #3b5998;
}

.google-account {
    padding: 5px 15px 5px 15px;
    background: #c00;
}

.microsoft-account {
    padding: 5px 15px 5px 15px;
    background: #f04f26;
}

.twitter-account {
    background: #55acee;
}

.linkedin-account {
    background: #1a85bc;
}

.pad-multiline {
    width: 32px;
    padding: 15px 0 0 0;
}

.pad-multiline1 {
    line-height: 20px;
    padding: 4px 0 0 0;
}

.icon-yellow-background {
    border-radius: 50%;
    background: @brand-yellow;
    width: 40px;
    height: 40px;
    color: white;
}

.icon-green-background {
    border-radius: 50%;
    background: @brand-green;
    width: 40px;
    height: 40px;
    color: white;
}

.form-label {
    font-size: 14px;
    font-weight: 700;
    float: left;
    position: relative;
    top: 5px;
    color: @brand-grey6;
    margin-top: 5px;

    .l-100 {
        width: 100px;
    }

    .l-110 {
        width: 110px;
    }

    .l-120 {
        width: 120px;
    }

    .l-130 {
        width: 130px;
    }

    .l-140 {
        width: 140px;
    }

    .l-150 {
        width: 150px;
    }
}

.rotate45 {
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}



.display-inline {
    display: inline-block;
}

div.card {
    background-color: #fff;
}

div.card div.card-header {
    padding: 11px 20px 3px;
    background-color: @brand-beige;
    line-height: 24px;
    min-height: 24px;
    font-size: 16px;
    display: table;
    width: 100%;
    height: 40px;
    padding: 0 10px;
    box-sizing: border-box;

    i {
        font-size: 30px;
        position: relative;
        line-height: 24px !important;
    }

    span {
        display: table-cell;
        vertical-align: middle;
    }
}

div.card div.card-header.owner-style {
    background-color: @brand-grey1;
}

div.card div.card-header.tenant-style {
    background-color: @brand-grey1;
}

div.card div.card-content {
    padding: 20px;
    position: relative;
    border: 1px solid @brand-grey3;
    border-top: none;
}

div.card-footer {
    padding: 10px;
    background-color: #efefef;
    margin: 10px -15px -30px;
}

div.card-gray {
    background-color: #fff;
    border: 1px solid #dbdbdb;
}

div.card-gray div.card-gray-header {
    padding: 7px;
    line-height: 24px;
    min-height: 24px;
    font-size: 20px;
}

div.card-gray div.card-gray-content {
    padding: 20px;
    position: relative;
}

.switch-ios.switch-light a {
    left: 2px;
}

.switch-light {
    width: 60px;
    border-radius: 20px;
    margin-left: 0px;
    cursor: pointer;
    position: relative;
    top: -2px;

    > a {
        position: absolute;
        right: 50%;
        top: 0;
        z-index: 4;
        display: block;
        height: 23px;
        padding: 0;
        box-shadow: none !important;
        border: none !important;
        width: 1.700em !important;
    }

    > span {
        height: 100%;
        margin-top: -1.7px;
        padding: 0 2px 0 2px;

        > span {
            margin-top: 1.25px;
            margin-left: 6.5em;

            > i {
                font-size: 24px;
                color: #fff;
            }
        }
    }
}


.time-picker-input {
    width: 100px;
    padding: 0 30px 0 5px;
}

.switch-ios.switch-light input:checked ~ span:before {
    background-color: @brand-green;
    border-color: @brand-green;
    border-color: @brand-green;
    box-shadow: inset 0 0 0 30px @brand-green;
    border: 1px solid @brand-green;
}

.switch-ios.switch-light > span:before {
    background-color: @brand-danger;
    border: 1px solid @brand-danger;
    color: #fff;
    box-shadow: inset rgba(0, 0, 0, 0) 0 0px 0;
}

.btn-primary {
    color: #fff;
    background-color: #428bca;
    border-color: #357ebd;
    border-radius: 50px;
}

.snapshot {
    background-color: #fff;
    padding: 20px 20px 8px;
    margin: -10px -15px;
    font-size: 22px;
    font-weight: 600;
    border-bottom: 1px solid @brand-grey3;

    .ss-details {
        margin-bottom: 5px;
        font-weight: 400;
        display: inline-block;
        position: relative;
        top: -12px;
        font-size: 18px;
    }

    .circle {
        margin-left: auto;
        margin-right: auto;
        border-radius: 50%;
        width: 100%;
        position: relative;
        background-color: #fff;
        width: 35px;
        height: 35px;
        display: inline-block;
    }

    .circle-border {
        background: @brand-blue;
    }

    .circle:before {
        content: "";
        display: block;
        padding-top: 100%;
    }

    .circle-inner {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        text-align: center;
    }

    .score-text {
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: 24px;
        line-height: 22px;
        font-size: 22px;
        color: #FFF;
    }
}

.snapshot-generic {
    background-color: rgba(211, 211, 211, 0.24);
    padding: 20px 20px 25px;
    margin: -10px -15px;
    font-size: 26px;
    font-weight: 600;
    border-bottom: 1px solid @brand-grey3;
}

.font24 {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -1px;
}

.font22 {
    font-size: 22px;
    line-height: 22px;
    font-weight: 700;
    letter-spacing: -1px;
}

.font20 {
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
    letter-spacing: -1px;
}

.pointer {
    cursor: pointer;
}


#alertFlash {
    .alert {
        position: fixed;
        top: 40px;
        z-index: 2;
        width: 40%;
        height: 72px;
        text-align: center;
        border-radius: 5px;
        padding: 0;
        border: none;
        font-size: 16px;
    }

    .close {
        font-size: 30px;
        color: #FFF;
        opacity: 1;
        margin-right: 15px;
        font-weight: unset;
    }

    .alert-error {
        background: @brand-danger;
        color: #FFF;
        z-index: 1000;
    }

    .alert-success {
        background: @brand-success-green;
        color: #FFF;
        z-index: 1000;
    }

    .alert-info {
        background: @brand-info;
        color: #FFF;
        z-index: 1000;
    }

    .alert-table {
        height: 72px;
        width: 100%;
    }

    .message {
        padding: 0;
    }

    .icon-container {
        font-size: 40px;
        margin-left: 15px;
    }
}

.pageBg {
    min-height: 100%;
    background-color: #ffffff; 
}

.graybg {
    background-color: @brand-grey4;
    height: 15px;
}

.page-header-holder {
    .shader {
        height: 30px;
        .graybg;
    }
}

.page-header {
    position: relative;
    margin: 0px;
    padding: 15px 15px 15px 30px;
    background-color: white;
    border-bottom: @brand-grey3 1px solid;

    @media (max-width:480px) {
        margin: 10px 0 0;
        padding: 15px;
    }

    h1 {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    &.title-dashboard {
        border: none;
        padding: 0 0 30px 0;
    }

    .actions {
        position: absolute;
        top: 20px;
        right: 10px;

        @media( max-width:768px ) {
            top: 12px;
        }
    }

    .shader {
        border-top: solid 1px @brand-grey3;
        height: 30px;
        background-color: #ddd;
    }

    &-route{
        font-size: 18px;
        color: #FFF;
        margin-left: 5px;
    }
}

.page-content {
    height: 100%;
    background-color: white;
    padding: 20px 30px 50px;
}

.btn {
    height: 35px;
    .transition-all(0.5s);
    border-radius: 0;

    &.mini {
        height: 24px;
    }
}

.btn-link {
    .transition-all(0.5s);
}

.btn-link:hover {
    text-decoration: none;
}

.btn.primary {
    background: @brand-blue;
    color: white;
    border-radius: 25px;
    font-weight: 700;
    padding: 0 26px;

    > i {
        font-size: 22px;
        padding: 0 5px 0 0;
    }

    &.dropdown-toggle {
        color: #fff;
    }
}

.btn.primary:hover, .btn.primary:focus {
    background: none;
    color: @brand-blue;
    box-shadow: inset 0px 0px 0px 4px @brand-blue;
}

.btn.secondary {
    background: transparent;
    color: black;
    border-radius: 0px;
    padding: 0;
}

.btn.popover-helper { // this stops the popover from flickering when you move off the button and move into the popover
    padding: 10px;
    min-width: 275px;
    cursor: pointer;
}

.full-width {
    width: 100%;
}
// the following 2 classes are needed due to a rendering error in 1px borders that is effecting tinymce
.mce-container {
    border-right: none !important;
}

.tinymce-form-wrapper {
    border-right: 1px solid #9e9e9e;
    border-radius: 0px;
}

.custom_scrollbar {
    height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
}

.custom_scrollbar::-webkit-scrollbar {
    width: 8px;
    height: 25px;
}

.custom_scrollbar::-webkit-scrollbar-track {
    background-color: rgba(113,112,107,0.1);
    -webkit-border-radius: 5px;
}

.custom_scrollbar::-webkit-scrollbar-thumb:vertical {
    background-color: @brand-blue;
    -webkit-border-radius: 4px;
    height: 25px;
    width: 8px;
}

.custom_scrollbar::-webkit-scrollbar-thumb:vertical:hover,
.custom_scrollbar::-webkit-scrollbar-thumb:horizontal:hover {
    background: @brand-blue;
}

.custom_scrollbar::-webkit-scrollbar-thumb:horizontal {
    background-color: @brand-blue;
    -webkit-border-radius: 4px;
}

/* Loader Elements */
.loader-overlay {
    background-color: #fff;
    filter: alpha(opacity=40);
    opacity: 0.4;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 200px;
    left: 0;
    z-index: 200;
}

.modal-container .loader-overlay {
    position: absolute;
    top: 0px;
}

/* Links */
a, .link-primary {
    color: #FFF;
    .transition-all (0.3s);
    cursor: pointer;
}

a:focus,
a:hover {
    color: @brand-blue3;
    text-decoration: none;
    cursor: pointer;
}

a.context-menu-link {
    color: black !important;
    font-size: 14px !important;
}

.anim-underline {
    position: relative;
    text-decoration: none;
}

a.link-white {
    color: lighten(#000, 100%);
    position: relative;
    text-decoration: none;
}

a.link-white:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: #fff;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

a.link-blue:focus:before,
a.link-blue:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

a.link-blue {
    color: @brand-blue;
    position: relative;
    text-decoration: none;
}

a.link-blue:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: @brand-blue;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

a.link-blue:focus:before,
a.link-blue:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}

.link-black:focus,
.link-black:hover {
    color: @brand-black;
    text-decoration: none;
    cursor: pointer;
}

.link-black {
    color: @brand-black;
    position: relative;
    text-decoration: none;
}

.link-black:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    background-color: @brand-black;
    visibility: hidden;
    -webkit-transform: scaleX(0);
    transform: scaleX(0);
    -webkit-transition: all 0.3s ease-in-out 0s;
    transition: all 0.3s ease-in-out 0s;
}

.link-black:focus:before,
.link-black:hover:before {
    visibility: visible;
    -webkit-transform: scaleX(1);
    transform: scaleX(1);
}



/* Colors */
.white {
    color: lighten(#000, 100%);
}

.green {
    color: @brand-green;
}

.blue {
    color: @brand-blue;
}

.bg-beige {
    background: @brand-grey1;
}


.hr-white {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    height: 1px;
    font-size: 0;
    clear: both;
}

.more-info {
    padding: 2px;
    border: 1px solid @brand-black;
    font-size: 18px;
    cursor: pointer;
}

.more-info:hover {
    background: @brand-yellow;
    color: white;
    border: 1px solid @brand-yellow;
}

/* Text */
.h1, h1 {
    font-size: 22px;
}

.h2, h2 {
    font-weight: 600;
    font-size: 20px;
    margin: 8px 0;
}

.font-bold {
    font-weight: bold;
}

.h3, h3 {
    font-size: 20px;
    font-weight: 600;
    margin: 0;
    padding: 0;
}

.text-large {
    font-size: 18px;
    letter-spacing: -1px;
    font-weight: 700;
}

.text-large-no-bold {
    font-size: 18px;
    letter-spacing: -1px;
}

.text-jumbo {
    font-size: 32px;
    letter-spacing: -2px;
    font-weight: 600;
}

.text-small {
    font-size: 12px;
}

.text-link {
    color: @brand-blue;
    font-weight: 700;
    letter-spacing: -1px;
}

.text-info {
    color: @brand-notification;
}

.text-transform-upper {
    text-transform: uppercase;
}

/* background colors*/

.green-bg {
    background-color: @base-green;
}

.yellow-bg {
    background-color: @base-yellow;
}

.blue-bg {
    background-color: @base-blue;
}

.dark-blue-bg {
    background-color: @base-dark-blue;
}

.orange-bg {
    background-color: @base-orange;
}

.dark-grey-bg {
    background-color: @base-dark-grey;
}


/* Shapes */
.pill-container {
    margin: 10px 20px 0 0;
    display: inline-block;
}

.pill-wrapper {
    padding: 2px 18px;
    border-radius: 20px;
    font-weight: 600;
    font-size: 16px;
    text-transform: capitalize;
}

.anchor-btn-primary {
    background-color: @brand-blue;
    color: white !important;
    padding: 5px 18px;
    border-radius: 20px;
    font-size: 16px;

    span {
        color: white !important;
    }
}

.pill-wrapper-text {
    margin: 0 40px 0 5px;
    font-size: 20px;
    font-weight: 700;
    position: relative;
    top: 2px;
}

.pill-green {
    background-color: @brand-success-green;
    color: white;
}

.pill-green-white {
    background-color: #fff;
    color: @brand-green;
    border: 2px solid @brand-success-green;
}

.pill-yellow {
    background-color: @brand-yellow;
    color: white;
}

.pill-blue {
    background-color: @brand-blue2;
    color: white;
}

.pill-red {
    background-color: @brand-danger;
    color: white;
}

.pill-green-text {
    color: @brand-success-green;
}

.pill-yellow-text {
    color: @brand-black;
}

.pill-blue-text {
    color: @brand-blue2;
}

.pill-red-text {
    color: @brand-danger;
}

.pill-blue-light-text {
    color: @brand-notification;
}

.icon-wink {
    color: @brand-success-green;
    font-size: 30px;
    position: relative;
    top: 6px;
}

.icon-notification {
    color: @brand-notification;
    font-size: 30px;
    position: relative;
}

.icon-success {
    color: @brand-success-green;
    font-size: 30px;
    position: relative;
}

.icon-square {
    height: 12px;
    width: 12px;
}

.square-label {
    position: relative;
    top: -4px;
    left: 5px;
}

.icon-large {
    font-size: 32px;
}

.hspace40 {
    margin-left: 40px;
}

.pad-top-5 {
    padding-top: 5px;
}

.logo-style {
    width: 230px;
}

.text-area-local {
    height: 120px !important;
    resize: none;
}

.attachment-icon {
    color: @brand-primary;
}

.input-align-radio {
    vertical-align: text-top;
}

/* Accordions */
#accordion, #accordion2 {
    .panel {
        border-radius: 0;
        border: 0;
        margin: 0;
    }

    + panel {
        margin: 0;
    }

    .panel-heading {
        background-color: @brand-beige;
        border-radius: 0;
        padding: 0;
        border-bottom: 1px solid #8e7949;
    }

    .panel-title {
        font-size: 14px;

        a {
            display: block;
            padding: 15px;
        }

        a:hover {
            color: black;
            text-decoration: none;
        }
    }
}

.accordion-toggle {
    display: block;
}

.panel-group .panel {
    border-radius: 0;
}

.open {
    display: block;
}

.panel {
    border-radius: 0;
}

.panel-heading {
    border-top-right-radius: 0;
    border-top-left-radius: 0;
}

/* Dropdown */
.dropdown {

    .dropdown-toggle {
        color: #000;
        cursor: pointer;
        display: inline-block;
        width: 100%;
    }

    .greeting {
        font-size: 16px !important;
        color: #FFF !important;
        background: none;

        i {
            font-size: 12px !important;
            position: relative;
            top: 0px !important;
        }
    }
}

.uib-datepicker-popup.dropdown-menu {
    min-width: 300px;
    float: none;
    margin: -2px 0 0 0;
    padding: 10px;
    max-height: 700px;

    table.uib-daypicker {
        margin: 0 auto;
    }
}


.dropdown-menu {
    max-height: 280px;
    overflow: auto;
    border-radius: 4px;
    width: 100%;
    min-width: 250px;
    border: unset;

    li a {
        font-size: 14px;
        font-weight: 600;
        padding: 10px 10px;
        white-space: normal;

        span {
            position: relative;
            top: -4px;
        }
    }

    i {
        font-size: 24px;
    }
}

.do-not-display {
    display: none !important;
}

/* Table */
.table {

    thead {
        background: @brand-grey1;
        text-transform: uppercase;
        font-weight: 700;

        tr {
            height: 40px;

            th {
                vertical-align: middle;
            }
        }
    }

    thead.owner-style {
        background: @brand-blue;

        th {
            color: #fff;
        }
    }

    thead.tenant-style {
        background: @brand-yellow;

        th {
            color: #fff;
        }
    }

    > tbody > tr {
        &:last-child {
            border-bottom: 1px solid @brand-grey1;
        }

        > td {
            padding: 16px 8px;
        }
    }



    .st-selected {
        background: #f4e9d7 !important;
    }

    .st-sort-ascent::after {
        font-family: icomoon;
        font-size: 8px;
        content: "\e608";
    }

    .st-sort-descent::after {
        font-family: icomoon;
        font-size: 8px;
        content: "\e609";
    }

    .tabel-icon {
        font-size: 20px;
        display: block;
    }

    .tabel-icon-wrapper {
        height: 20px;
    }

    .table-width-mini {
        min-width: 700px;
        overflow: scroll;
    }
}

.table-striped > tbody > tr:nth-child(2n+1).st-selected > td {
    background: @brand-grey5 !important;
}

.table > tbody > tr:hover {
    cursor: pointer;
}

/* GRID / TABLE */
.grid-container-outer {
    max-width: 100%;

    @media( max-width:991px ) {
        margin-bottom: 20px;
    }
}

.grid-container-inner {
    min-width: 700px;
}

.grid-container-inner-small {
    min-width: 480px;
}

.ellipses {
    //width or diplay mode block needs to be applied on the element for the ellipsis to show.  do not apply it here
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding: 10px 0;
}

.ellipses2 {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
}

.default-text {
    display: block;
    color: @brand-black;
    font-weight: normal;

    &:hover {
        color: @brand-black;
    }
}

.no-text-icon {
    padding: 10px;
    background: @brand-yellow;
    color: white;
    border-radius: 50%;
}

.no-text-td {
    height: 120px;
    text-align: center;
    padding: 50px !important;
}

.no-text {
    text-align: center;
    padding: 100px 0 !important;
}

.tick {
    font-size: 24px;
}

.row-style {
    height: 45px;
    font-weight: bolder;
    letter-spacing: -1px;
}

.row-style-color-gray {
    color: @brand-grey3;
}

.row-style-color-black {
    color: #000;
}

.circle-tick {
    padding: 6px;
    color: white;
    border-radius: 50%;
    width: 39px;
    height: 39px;
    position: relative;
    display: inline-block;
}

.circle-last {
    padding-left: 25px;
}

.yellow-background {
    background-color: @brand-yellow;
}

.green-background {
    background-color: @brand-green;
}

.light-blue-background {
    background-color: @brand-notification;
}

.gray-lighter-background {
    background-color: @gray-lighter;
}

.circle-empty {
    padding: 4px;
    background: #fff;
    border: 5px solid #dbdbdb;
    border-radius: 50%;
    position: relative;
    background-color: white;
    width: 39px;
    height: 39px;
    display: inline-block;
}

.seperator {
    width: 100%;
    height: 2px;
    background-color: #dbdbdb;
}

.loading-blocker {
    filter: alpha(opacity=40);
    opacity: 0.4;
    background-color: @brand-grey4;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
}

.loading {
    zoom: 1;
    position: relative;
    min-height: 40px;
}

.no-border {
    border: none !important;
}

.cell-container {
    height: 55px;
    vertical-align: middle !important;
}

.cell-container > div {
    width: 100%;
    overflow: hidden;
    vertical-align: middle !important;
}

.text-red {
    color: @brand-danger;
    font-weight: 700;
}

/* Popover */
.popover {
    min-width: 200px;
    padding: 0;
    border: unset;
}

.popover-title {
    background-color: @brand-blue;
    font-weight: bold;
    color: #FFF;
}

.popover-content {
    font-size: 14px;
    padding: 10px !important;
    color: #000;
}

/* Helpers */
.position-fixed {
    position: fixed;
}

.underline-black {
    border-bottom: 1px solid #000;
}

.underline-grey {
    border-bottom: 1px solid @brand-grey3;
    margin-bottom: 20px;
}

.underline-grey-small {
    border-bottom: 1px solid @brand-grey3;
    margin-bottom: 5px;
}

.spacer5 {
    height: 5px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.spacer10 {
    height: 10px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.spacer20 {
    height: 20px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.spacer30 {
    height: 30px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.spacer40 {
    height: 40px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.spacer50 {
    height: 50px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.spacer100 {
    height: 100px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.spacer200 {
    height: 200px;
    width: 100%;
    font-size: 0;
    margin: 0;
    padding: 0;
    border: 0;
    display: block;
}

.full-height {
    min-height: 100vh;
    position: relative;
}

.pad-top-bottom {
    padding-top: 10px;
    margin-bottom: 10px;
    padding-bottom: 5px;
}

.text-bold {
    font-weight: 700;
}

.tdCollapsible.handle {
    cursor: pointer;
    width: 100%;
    height: 40px;
    background-color: @brand-grey1;
    color: @brand-black;
    font-weight: 700;

    > ul {
        padding-bottom: 0 !important;
    }

    .expander-content {
        padding: 10px;
        text-transform: uppercase;
    }
}

.tdCollapsible-content {
    width: 100%;
    table-layout: fixed;
}

.td-ellipsis {
    width: 95%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

@media( min-width:1201px ) {

    .visible-xxs-block, .visible-xxs {
        display: none;
    }

    .hidden-xxs-block, .hidden-xxs {
        display: block;
    }
}

@media( max-width:1200px ) {

    .visible-xxs-block, .visible-xxs {
        display: none;
    }

    .hidden-xxs-block, .hidden-xxs {
        display: block;
    }
}


@media( max-width:992px ) {

    .visible-xxs-block, .visible-xxs {
        display: none;
    }

    .hidden-xxs-block, .hidden-xxs {
        display: block;
    }
}


@media( max-width:768px ) {
    .td-ellipsis {
        width: 65%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .visible-xxs-block, .visible-xxs {
        display: none;
    }

    .hidden-xxs-block, .hidden-xxs {
        display: block;
    }
}

@media( max-width:480px ) {
    .td-ellipsis {
        width: 65%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .visible-xxs-block, .visible-xxs {
        display: block;
    }

    .hidden-xxs-block, .hidden-xxs {
        display: none;
    }
}

.td-full-width {
    width: 100% !important;
}

.td-no-wrap {
    white-space: nowrap;
}

.status-div {
    width: 15px !important;
    height: 40px;
}

.non-status-div {
    width: 100%;
}

.job-details-small {
    height: 200px !important;
    overflow: auto;
    border-bottom: 1px solid @brand-grey3;
}

.job-details-large {
    height: 200px;
    overflow: auto;
    border-bottom: 1px solid @brand-grey3;
}

.expander-tables {
    border-left: 1px solid @brand-grey3;
    border-right: 1px solid @brand-grey3;
    border-bottom: 1px solid @brand-grey3;
    padding: 20px;
}

.td-last-item {
    padding-left: 15px;
    padding-bottom: 20px;
}

/* Styling for the ngProgress itself */
#ngProgress {
    margin: 0;
    padding: 0;
    z-index: 99998;
    background-color: @brand-blue !important;
    box-shadow: 0 0 0 !important; /* Inherits the font color */
    height: 5px !important;
    opacity: 0;
    /* Add CSS3 styles for transition smoothing */
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
}

/* Styling for the ngProgress-container */
#ngProgress-container {
    position: fixed;
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99999;
}

.nice-scroll {
    transition: none !important;
}

.nicescroll-rails > div {
    cursor: pointer;
}

.device-sm {
    display: none;
}

.device-md {
    display: block;
}

.device-xs {
    display: none;
}

.device-lg {
    display: block;
}
.page-content {
    .nav-tabs {
        padding:0;
    }
    .nav-tabs > li {
        font-size: 16px;

        & > a {
            color: @brand-grey2;
            border-radius: 0;
            border: none !important;
            border-bottom: 5px solid transparent !important;
            font-weight: 400;
            padding: 10px 20px 10px 20px;
            font-size: 18px;
            letter-spacing: 0px;

            &:hover, &:focus {
                background-color: transparent;
                color: @brand-black;
            }
        }

        &.active, &.focus {
            & > a {
                background-color: transparent;
                border-radius: 0;
                border: none !important;
                border-bottom: solid 5px #333 !important;
                color: @brand-black;
                padding: 10px 20px 10px 20px;
            }

            & > a:focus, & > a:hover {
                background-color: transparent;
                color: @brand-black;
            }
        }
    }
}



/*-----------------------------------------------------------------------------------*/
/*	02: LOGIN
/*-----------------------------------------------------------------------------------*/

.new-login-page {
    @header-height: 8%;
    min-width: 320px;
    background: url(../images/homepage/hp-blackground.png);
    background-repeat: repeat;
    background-size: contain;
    background-color: transparent;
    transition: all 0.5s ease-in-out;

    .logo {
        width: 240px;
        display:block;
        margin:auto;
    }

    .login-forgot {
        font-weight: 600;
        font-size: 14px;
        color: @brand-blue4;
        margin: @login-spacer 0;
    }

    .login-spacer {
        margin: @login-spacer 0;
    }

    .portal-btn {
        width: 225px;
        height: 32px;
        outline: none;
        border-radius: 20px;
        font-weight: 600;

        @media (max-width:480px) {
            width: 145px;
        }

        &-primary {
            background: @brand-blue4;
            color: white;
            border-color: transparent;
        }

        &-outline {
            background: white;
            color: @brand-blue4;
            border: 1px solid @brand-blue4;

            &:hover {
                color: #FFF;
            }
        }
    }

    .portal-btn:hover, .portal-btn:focus {
        background: @brand-grey6;
    }

    .control-label {
        top: -5px;
        position:relative;
    }

    .input-trans {
        transition: all 0.3s;
        background: transparent;
        border-bottom: 1px solid @brand-grey6;
        border-top: 1px solid white;
        border-left: 1px solid white;
        border-right: 1px solid white;
        box-shadow: none;

        &:focus {
            background: rgba(0, 0, 0, 0.05);
        }
    }

    .error-bg {
        color: @brand-danger;
        padding: 5px 10px;
        margin: 10px 0 0;
        font-weight: 600;
    }


    @media (max-width:480px) {
        .container {
            width: 100%;
        }
    }

    .nav > li > a:hover {
        background-color: transparent;
    }

    .navbar {
        height: @header-height;

        .navbar-brand {
            padding: 16px 30px;

            .login-logo {
                font-size: 60px;
            }
        }

        .navbar-nav {
            margin-top: 30px;

            li.active {
                transition: all 0.5 ease-in-out;
                color: @brand-green;
            }
        }

        @media( max-width:480px ) {
            .navbar-brand {
                padding: 16px 15px;
            }
        }


        @media(max-width:767px) {
            .agents-link {
                position: absolute;
                top: 0;
                right: 0;
            }
        }


        .pt-reference-link {
            padding: 5px 0;
            font-size: 16px !important;
            line-height: 16px !important;
            color: @brand-blue; /* IE8 proofing */
            border: 1px solid @brand-blue;
            border-radius: 25px;
            font-weight: bold;
            width: 160px;
            text-align: center;
            margin-left: 15px;
            margin-top: -2px;


            &:hover {
                color: #fff;
                background: @brand-green;
            }
        }

        .navbar-nav > li > a {
            font-size: 20px;
            line-height: 25px;
            letter-spacing: -1px;
        }

        .navbar-nav li {

            &:last-child a {
                margin-right: 0;
            }
        }
    }


    .section {
        padding: 50px 0;

        h2 {
            font-size: 55px;
            font-weight: 600;
            border-bottom: solid 1px #000;
            margin-bottom: 30px;
            letter-spacing: -4px;
        }

        h3 {
            font-size: 24px;
            font-weight: 700;
            margin-bottom: 30px;
        }

        @media( max-width:991px ) {
            h2 {
                font-size: 24px;
                letter-spacing: -2px;
            }

            h3 {
                font-size: 16px;
            }
        }

        .backToTop-shape {
            width: 0px;
            height: 0px;
            border-style: solid;
            border-width: 0 4px 8px 4px;
            border-color: transparent transparent @brand-green transparent;
            display: inline-block;
            position: relative;
            top: -1px;
        }

        .backToTop {
            border: solid 2px @brand-green;
            border-radius: 60px;
            padding: 2px 10px;
            font-size: 14px;

            .glyphicon {
                color: @brand-green;
            }
        }
    }

    .cover-section {
        min-height: 100vh;
        position: relative;

        .cover {
            padding: @header-height 0;
            position: relative;
            transition: all 0.5s ease-out;
            width: 100%;

            .cover-header {
                font-weight: 600;
                letter-spacing: 0;

                h3 {
                    font-size: 20px;
                    color: @brand-blue4;
                    margin: @login-spacer 0;
                }

                p {
                    font-size: 16px;
                    color: @brand-grey6;
                    margin: @login-spacer 0;
                }

                @media(max-width:991px) {
                    h3.intro {
                        font-size: 16px;
                        text-align: left;
                        margin-bottom: 10px;
                    }
                }
            }

            .login-title {
                text-align: left;
                font-size: 20px;
                font-weight: 600;
                color: #fff;
                margin-bottom: 20px;
                letter-spacing: -0.5px;
                text-shadow: 0 1px 2px rgba(0,0,0,0.3);
            }

            .last-loggedIn {
                font-size: 14px;
                text-align: left;
                color: #fff;
                margin-bottom: 10px;
            }

            .socialteam-wrapper {
                margin: @login-spacer 0;
                display: flex;
                justify-content: space-around;
            }

            .socialteam {
                display: inline-block;
                border-radius: 200px;
                text-align: left;
                color: #fff;
                cursor: pointer;
                *display: inline;
                zoom: 1;
                width: 50px;
                transition: all 0.1s;
                //transform: scale(0.9,0.9);
                @media (max-width:768px) {
                    span {
                        font-size: 14px;
                    }
                }

                .social-element {
                    border-radius: 50%;
                    width: 32px;
                    height: 32px;
                    display: inline-block;
                    border: 4px solid transparent;


                    .social-icon {
                        position: relative;
                        top: 4px;
                        left: 4px;
                        font-size: 16px;
                        font-weight: normal;
                    }

                    &.facebook {
                        background: #3b5998;
                        transition: all 0.3s ease-in-out;
                    }

                    &.google {
                        background: #cc0000;
                        transition: all 0.3s ease-in-out;
                    }

                    &.microsoft {
                        background: #f04f26;
                        transition: all 0.3s ease-in-out;
                    }

                    &.twitter {
                        background: #55acee;
                        transition: all 0.3s ease-in-out;
                    }

                    &.linkedin {
                        background: #1a85bc;
                        transition: all 0.3s ease-in-out;
                    }
                }
            }

            .line-right, .line-left {
                border-top: 1px solid #777777;
                height: 1px;
                display: inline-block;
            }

            .line-right {
                width: 45%;
                float: right;

                @media (max-width:991px) {
                    & {
                        width: 43.5%;
                    }
                }

                @media (max-width:767px) {
                    & {
                        width: 45%;
                    }
                }

                @media (max-width:480px) {
                    & {
                        width: 41.5%;
                    }
                }
            }

            .line-left {
                width: 45%;
                float: left;
            }

            .text-media {
                color: @brand-grey6;
                font-size: 14px;
                font-weight: 600;
                margin: @login-spacer 0;
            }

            .btn-logins {
                font-size: 18px;
                color: #fff;
                font-weight: 600;

                img {
                    width: 16px;
                    height: auto;
                }
            }

            &.fitlogin {
                margin-top: -26px;
            }
        }

        .hp-login {
            margin: 0 auto;
            position: relative;
            max-width: 480px;
            min-width: 480px;
            height: 100%;

            @media (max-width:480px) {
                & {
                    max-width: 320px;
                    min-width: 320px;
                }
            }

            @media (max-width: 991px) {
                & {
                    min-height: 635px;
                }
            }

            .no-account {
                position: absolute;
                bottom: 5px;
                right: 5px;
                color: #fff;
                font-size: 14px;
                min-width: 300px;
            }
        }

        .hp-header {
            transition: all 0.5s ease-out;
            text-shadow: 1px 1px 6px rgba(0, 0, 0, 0.4);

            .header-box {
                margin: 0 30px;
            }
        }

        h1.cover-title {
            color: #fff;
            font-size: 44px;
            text-transform: uppercase;
            letter-spacing: -2px;
            line-height: 50px;
            margin-top: 0;
            text-shadow: 0 1px 2px rgba(0,0,0,0.9);
            font-weight: 600;


            @media (max-width:1200px) {
                font-size: 44px;
                letter-spacing: -3px;
                line-height: 50px;
            }

            @media (max-width:992px) {
                font-size: 30px;
                text-align: left;
                color: #fff;
                font-weight: 600;
                margin-bottom: 30px;
                line-height: 30px;
                letter-spacing: -2px;
                max-width: 350px;
            }
        }

        .pt-client-home-header {
            margin-top: @header-height;
        }

        .pt-client-home-tagline {
            margin-top: 50px;

            img {
                width: 100%;
                max-width: 392px;
            }
        }
    }

    .intro-section {
        .list-media {
            display: flex;
            flex-direction: column;
            flex: 0 1 100%;
            height: 350px;
        }

        .media {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: row;
            flex: 0 1 100%;

            .media-object {
                margin-right: 10px;
                width: 85px;
                flex: 85px;
                text-align: center;
            }

            .media-body {
                flex: 0 1 100%;
                margin-left: 20px;
                width: 400px;

                .media-heading {
                    font-weight: 700;
                    margin-bottom: 2px;
                }
            }

            .icon {
                width: 65px;
                height: 65px;
            }
        }

        .intro-boxed {
            text-align: center;
            font-weight: 700;
            font-size: 22px;
            border: solid 6px #efefef;
            padding: 24px 15px 10px;
            margin: 50px 0 0;
            letter-spacing: -1px;
            background-color: #efefef;

            p {
                line-height: 25px;
            }

            @media (max-width:991px ) {
                margin: 0;
            }
        }

        @media (max-width:991px ) {
            .intro-img-holder img {
                margin: 0 auto 30px;
            }
        }

        @media (min-width:481px) and (max-width:991px ) {

            .list-media {
                margin-top: 30px;
                flex-direction: row;
                width: 100%;
                height: auto;
                margin-bottom: 50px;
            }

            .media {
                display: block;
                text-align: center;
                margin-top: 0;
                width: 30%;


                .media-object {
                    width: 100%;
                    margin-bottom: 10px;
                }

                .media-body {
                    margin: 0;
                }
            }
        }

        @media(max-width:480px) {
            .list-media {
                display: block;
                margin-top: 30px;
            }

            .media {
                margin: 0 0 50px;
                width: 100%;
            }
        }
    }

    .nav-tabs {
        padding:0;
        border-bottom: solid 1px @brand-grey6;
    }

    .nav-tabs > li {
        font-size: 16px;

        & > a {
            color: #a5a5a5;
            border-radius: 0;
            border: none;
            border-bottom: 5px solid transparent !important;
            font-weight: 700;
            padding: 10px 40px 10px 0;

            &:hover, &:focus {
                border-color: transparent;
                color: @brand-black;
            }
        }

        &.active, &.focus {
            & > a {
                background-color: transparent;
                border-radius: 0;
                border: none;
                border-bottom: solid 5px #333 !important;
                color: @brand-black;
                padding: 10px 40px 10px 0;
            }

            & > a:focus, & > a:hover {
                background-color: transparent;
                color: @brand-black;
            }
        }
    }

    .newaccount-wrapper .nav-tabs > li {
        > a {
            font-weight: 600;
            padding: 10px 0;
            display: flex;
            justify-content: center;
        }

        &.active, &.focus {
            & > a {
                border-bottom: solid 7px @brand-blue4 !important;
                color: @brand-grey6;
            }
        }
    }

    .features-section {
        box-shadow: inset 0 7px 50px -7px rgba(0,0,0,0.15);

        .list-feature {
            dt {
                font-size: 18px;
                font-weight: 700;
            }

            dd {
                margin: 0;
                display: flex;
                flex-direction: row;
                flex: 0 1 100%;
                align-items: center;

                .icon-tick {
                    font-size: 30px;
                    color: @brand-green;
                    flex: 0 40px;

                    &.long-text {
                        align-self: flex-start;
                    }
                }

                span {
                    flex: 0 1 100%;
                }
            }
        }
    }

    .about-section {
        padding: 0;
        color: #fff;

        h2 {
            border-bottom-color: #fff;
        }

        .about-container {
            padding: 50px 0;
            background: url(../images/homepage/hp-bg-pattern.png), url(../images/homepage/hp-about-bg-sm.jpg);
            background-repeat: repeat, no-repeat;
            background-size: auto, cover;
            background-color: transparent;
            transition: all 0.5s ease-in-out;

            @media (min-width:1200px) {
                & {
                    background: url(../images/homepage/hp-bg-pattern.png), url(../images/homepage/hp-about-bg-sm.jpg);
                    background-repeat: repeat, no-repeat;
                    background-size: cover;
                }
            }
        }
    }

    footer {
        background: #000;

        p {
            margin: 30px 0;
            text-align: center;
            font-size: 11px;
            color: #fff;
        }
    }
}

.hp-dialog {
    .q-holder {
        display: flex;
        flex: 0 1 100%;
        align-items: center;
    }

    .q-icon {
        border-radius: 50%;
        background-color: @brand-blue;
        color: #fff;
        font-size: 24px;
        font-weight: bold;
        position: relative;
        width: 45px;
        height: 45px;
        display: block;
        margin-right: 10px;

        &:after {
            position: absolute;
            content: 'Q';
            top: 5px;
            left: 13px;
        }
    }

    .q-text {
        font-size: 16px;
        font-weight: bold;
        flex: 205px;
    }

    .control-label {
        top: -5px;
        position:relative;
    }
}

/*-----------------------------------------------------------------------------------*/
/*	03: HEADER & MENU
/*-----------------------------------------------------------------------------------*/
.nav-tabs {
    padding:0 0 0 15px;
}
.navbar-header {
    padding:0 0 0 15px;
    @media (max-width: 767px) {
        padding:0;
    }
}
.nav-wrapper {
    background: @brand-blue;
    height: 72px;
}

.site-logo {
    font-size: 32px !important;
    color: #FFF;
    position: relative;
    top: 5px;
}

.navbar {
    min-height: 72px;
    margin-bottom: 0;

    .navbar-nav {
        padding-right: 15px;
        height: 72px;
    }

    li > {
        .dropdown-menu {
            margin-top: 8px;
            border-radius: 4px;
            border-top-right-radius: 4px;
            border-top-left-radius: 4px;
        }

        & ul > li i {
            font-size: 16px;
            position: relative;
            color: @brand-black;
        }
    }

    .dropdown-menu {
        width: 100%;
        padding: 0;
        -webkit-box-shadow: 0 6px 6px rgba(0,0,0,.175);
        box-shadow: 0 1px 6px rgba(0,0,0,.175);

        > li > a {
            &:hover {
                text-decoration: none;
                color: #000;
                background-color: @brand-grey4;
            }
        }
    }

    .email-notification {
        color: #FFF;
    }

    .nav > li {
        margin-right: 10px;
        color: #FFF;
        font-size: @font-size-small;
        font-weight: 700;
        margin: 0px;
        padding: 0px;
        display: inline;
        margin-right: 0px;

        > a {
            color: #FFF;
            font-size: @font-size-small;
            font-weight: 500;
            margin-top: 0px;
            line-height: 0;

            > i {
                font-size: 20px;
                position: relative;
            }
        }

        &:first-child {
            padding-right: 20px;
        }
    }

    .navbar-brand {
        height: 28px;
        color: #FFF;
    }

    .avatar > img {
        border-radius: 50%;
        border: 1px solid @gray-lighter;
        height: 34px;
        width: 34px;
        background: #fff;
        margin-top: -10px;
        margin-right: 10px;
        margin-left: 20px;
    }

    .eliptical {
        border: 0px;
        border-radius: 25px;
        background: #cc0000;
        color: white;
        padding: 1px 7px 2px;
        font-size: 10px;
        margin-left: -15px;
        position: relative;
        top: -15px;
    }
}

.navbar-collapse {
    padding-left: 0;
    padding-right: 0;
}


/* START NAVIGATION TABS */
.dropdown-menu-owner {
    background-color: #FFF;
    .border-radius(0);
    box-shadow: none;
    border: none;

    a {
        color: #162029 !important;
    }

    > li:hover > a, > li:focus > a {
        background: rgba(255, 255, 255, 0.3);
    }
}

.dropdown-menu-tenant {
    background-color: #FFF;
    .border-radius(0);
    box-shadow: none;
    border: none;

    > li > a {
        color: #162029 !important;
    }

    > li:hover > a, > li:focus > a {
        background: rgba(255, 255, 255, 0.3);
    }
}

.nav .open > a.owner-tab, .nav .open > a.owner-tab:focus, .nav .open > a.owner-tab:hover {
    background-color: @brand-blue;
    border-color: @brand-blue;
    color: #FFF;
}

.nav .open > a.tenant-tab, .nav .open > a.tenant-tab:focus, .nav .open > a.tenant-tab:hover {
    background-color: @brand-blue;
    border-color: @brand-blue;
    color: #FFF;
}


#dashboard ul.nav-tabs {
    margin: 0 0 30px 0 !important;
}

.tabnav {
    .nav-tabs {
        border: 0;

        .dropdown-menu {
            margin: 0;
        }
    }

    .nav-tabs > li {
        margin-bottom: 0;

        &:last-child {
            float: right;

            > a {
                padding-right: 0;

                &:hover {
                    background: none;
                }
            }
        }
    }

    .nav > li > a.owner-tab:focus, .nav > li > a.owner-tab:hover {
        background-color: @brand-blue;
        border: none;
        color: #FFF;
        border-radius: 0;
    }

    .nav > li > a.tenant-tab:focus, .nav > li > a.tenant-tab:hover {
        background-color: @brand-blue;
        border: none;
        color: #FFF;
        border-radius: 0;
    }

    .navbar-form.navbar-right:last-child {
        margin-right: 0px;
    }

    .form-control {
        border: none;
        border-radius: 0 !important;
        height: 40px;
    }

    .navbar-form {
        margin: 0;
        padding: 0;
    }

    ul {
        min-width: 350px;
    }

    .nav-tabs li ul li i {
        font-size: 24px;
        position: relative;
        top: 5px;
    }
}

.owner-style.tabnav {
    border-bottom: 3px solid @brand-blue;
}

.tenant-style.tabnav {
    border-bottom: 3px solid @brand-yellow;
}

.owner-style.nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
    border: none;
    background: @brand-blue;
    color: #fff;
    border-radius: 0;
    cursor: pointer;
}

.tenant-style.nav-tabs > li.active > a {
    border: none;
    background: @brand-yellow;
    color: #fff;
    border-radius: 0;
    cursor: pointer;
}
/* END NAVIGATION TABS */

.breadcrumb {
    font-size: 12px;
    margin-bottom: 0;
    padding: 7px 0 7px 15px;

    @media (max-width: 767px) {
        .breadcrumb {
            font-size: 12px;
            margin-bottom: 0;
            padding: 14px 0;
         }
    }

    a {
        font-weight: 700;
        color: #000;
    }
}

.breadcrumb > li + li:before {
    content: "|";
}

/*-----------------------------------------------------------------------------------*/
/*	04: DASHBOARD
/*-----------------------------------------------------------------------------------*/

.sidebar {
    display: none;
}
/* Sidebar navigation */
.nav-sidebar {
    margin-right: -21px; /* 20px padding + 1px border */
    margin-bottom: 20px;
    margin-left: -20px;

    > li > a {
        padding-right: 20px;
        padding-left: 20px;
    }
}

.nav-sidebar > .active > a,
.nav-sidebar > .active > a:hover,
.nav-sidebar > .active > a:focus {
    color: #fff;
    background-color: #428bca;
}

.line-padding {
    padding: 3px 0;
}

.panel-box {
    .panel-box-header {
        font-size: 24px;
        font-weight: 300;
        border-bottom: 1px solid @brand-black;

        > i:first-child {
            border-right: 1px solid @brand-grey3;
            padding-right: 10px;
            margin-right: 5px;
        }

        > i:last-child {
            margin: 2px;
        }
    }
}

.graph-from-label {
    margin-top: 10px;
    margin-right: 5px;
    float: left;
}

.graph-to-label {
    margin-top: 10px;
    margin-right: 25px;
    float: left;
}

.graph-date {
    min-width: 155px;
    white-space: nowrap;
}


/*-----------------------------------------------------------------------------------*/
/*	06: FOOTER
/*-----------------------------------------------------------------------------------*/

.owner-style.footer-container {
    background-color: @brand-grey5;
}

.tenant-style.footer-container {
    background-color: @brand-grey5;
}

.footer-spacer {
    clear: both;
    background-color: #fff;
}

.footer-container {
    margin: 0 auto 0;
    height: 40px;
    display: flex;
    align-items: center;
    width: 100%;
    bottom: 0;
    position: absolute;

    .divider {
        border-bottom: 1px solid #fff;
        margin: 0 auto;
    }

    .social-team {
        a {
            .transition-all(0.5s);
        }

        a:hover, a:focus {
            .footer-social-team {
                border: 4px solid #fff;
                background-color: #fff;
                padding: 3px 3px 3px 8px;
                width: 35px;
                height: 35px;

                i {
                    font-size: 10px;
                    color: @brand-blue;
                }
            }
        }
    }

    .footer-social-team {
        display: inline-block;
        .border-radius (50%);
        border: 4px solid #fff;
        padding: 3px 3px 3px 8px;
        width: 35px;
        height: 35px;

        i {
            font-size: 10px;
            color: #fff;
        }
    }

    .logos {
        padding: 0;

        img {
            margin: 0 40px 0 0;
            padding: 0;
        }
    }
}




/*-----------------------------------------------------------------------------------*/
/*	07: ANIMATION & EFFECTS
/*-----------------------------------------------------------------------------------*/
/* Stagger.css by Noah Addy */
/* A small CSS library to add Staggered Animations based on Dan Eden's animate.css */

.animationDelay (@delay) {
    -webkit-animation-delay: @delay;
    -moz-animation-delay: @delay;
    -ms-animation-delay: @delay;
    -o-animation-delay: @delay;
    animation-delay: @delay;
}

@interval: .15s; /* Stagger interval */

._1 {
    .animationDelay(1 * @interval);
}

._2 {
    .animationDelay(2 * @interval);
}

._3 {
    .animationDelay(3 * @interval);
}

._4 {
    .animationDelay(4 * @interval);
}

._5 {
    .animationDelay(5 * @interval);
}

._6 {
    .animationDelay(6 * @interval);
}

._7 {
    .animationDelay(7 * @interval);
}

._8 {
    .animationDelay(8 * @interval);
}

._9 {
    .animationDelay(9 * @interval);
}

._10 {
    .animationDelay(10 * @interval);
}




/*-----------------------------------------------------------------------------------*/
/*	08: MESSAGING PAGE
/*-----------------------------------------------------------------------------------*/

.animate-slide, .animate-slide2 {
    width: 50%;
}

.animate-slide.ng-enter, .animate-slide.ng-leave, .animate-slide2.ng-enter, .animate-slide2.ng-leave {
    -webkit-transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) .5s;
    transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) .5s;
}

.animate-slide.ng-enter,
.animate-slide.ng-leave.ng-leave-active {
    margin: 0 0 0 -2000px;
}

.animate-slide.ng-leave,
.animate-slide.ng-enter.ng-enter-active {
    margin: 0;
}

.animate-slide2.ng-enter,
.animate-slide2.ng-leave.ng-leave-active {
    margin: 0 0 0 2000px;
    overflow: hidden;
}

.animate-slide2.ng-leave,
.animate-slide2.ng-enter.ng-enter-active {
    margin: 0;
    overflow: hidden;
}

.contacts-box-header {
    background-color: @brand-grey1;
    padding: 8px 10px;

    h2, .h2 {
        font-weight: 700 !important;
    }
}

.contact-list {
    width: 100%;
    padding: 0 30px 0 0;

    .contact-list-inner {
        width: 100%;
        border: 1px solid @brand-grey3;
    }
}

.contact-mobile-nav {
    span {
        background-color: @brand-blue;
    }
}

.inbox-box-header {
    background-color: @brand-grey1;
    padding: 8px 10px;

    h2, .h2 {
        font-weight: 700 !important;
    }
}

.thread-box-header-wrapper {
    display: table;
    cursor: pointer;

    i.glyphicon {
        padding: 5px 10px;
        display: table-cell;
        color: #fff;
        vertical-align: middle;
    }

    &.owner-style {
        background-color: @brand-blue;
    }

    &.tenant-style {
        background-color: @brand-blue;
    }
}

.thread-box-header {
    color: white !important;
    font-weight: bold;
    padding: 13px 10px;
    display: block;

    &.owner-style {
        background-color: @brand-blue;
    }

    &.tenant-style {
        background-color: @brand-blue;
    }
}

.contacts-box-content {
    background-color: @brand-grey4;
}

.inbox-box-message {
    margin: 0 20px;
    padding: 10px 0;
    border-bottom: 1px solid @brand-grey3;
}

.inbox-box-content {
    border-left: 1px solid @brand-grey3;
    border-bottom: 1px solid @brand-grey3;
    border-right: 1px solid @brand-grey3;
}

.thread-box-content {
    background-color: @brand-grey4;
}

.coversation-box {
    border: 1px solid @brand-grey3;
}

.thread-title {
    margin-left: -1px;
    padding-left: 0px;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -1px;
    line-height: 26px;
}

.thread-unread-count {
    border: 0px;
    border-radius: 25px;
    background: #cc0000;
    color: white;
    padding: 1px 7px 2px;
    font-size: 10px;
    margin-left: -8px;
    position: relative;
    top: -2px;
}

.coversation-row {
    margin: 0px 10px;
    padding: 10px;
}

.coversation-row-content {
    padding: 10px;
}

.coversation-row-attachment {
    padding: 10px;
    background: @brand-grey4;
}

.contact-row {
    margin: 0px 10px;
    padding: 10px;
    border-bottom: 1px solid @brand-grey3;
}

.contact-row:hover {
    cursor: pointer;
}

.inbox-row {
}

.thread-row {
    padding: 10px 15px;
    cursor: pointer;
    border-bottom: 1px solid @brand-grey3;

    .ng-binding {
        padding: 2px 0;
    }
}

.thread-row-selected {
    background-color: @brand-grey1;
}

.thread-row:hover {
    background-color: @brand-grey1;
}

.inbox-row:hover {
    cursor: pointer;
    background-color: #efefef;
}

.attchment-link {
    color: black;
}

.attchment-icon {
    font-size: 25px;
}

.attchment-progress {
    border-left: 1px solid #9e9e9e;
    border-right: 1px solid #9e9e9e;
    border-bottom: 1px solid #9e9e9e;
    padding: 10px;
}

.attchment-progress-bar-outter {
    display: inline-block;
    vertical-align: middle;
    padding: 2px;
    border: 1px solid @brand-grey3;
    border-radius: 10px;
    width: 70%;
}

.attchment-progress-label {
    width: 20%;
    display: inline-block;
    white-space: nowrap;
}

.attchment-progress-cancel {
    width: 10%;
    font-size: 15px;
    vertical-align: middle;
    text-align: right;
    color: #9e9e9e;
    margin-left: 5px;
}

.attchment-progress-bar-inner {
    height: 5px;
    background: #ed6245;
    width: 60px;
    border-radius: 10px;
}

.message-compose-form {
    .control-label {
        text-align: left;
    }

    .dropdown-toggle {
        vertical-align: sub;
        display: inline-block;
    }
}

.compose-label {
    width: 150px;
}

.compose-value {
    font-size: 18px;
    font-weight: 700;
    color: @brand-blue2;
}

.search-contacts {
    > div {
        display: inline-block;
        width: 100%;

        i {
            font-size: 32px;
            position: relative;
            top: 4px;
        }
    }

    .search-input {
        input {
            padding: 0 15px 0 40px !important;
            margin: 0 0 0 -35px;
            border: 1px solid @brand-grey3;
        }

        i {
            font-size: 20px;
            color: @brand-grey3;
        }
    }
}

.input-group-btn:last-child > .btn, .input-group-btn:last-child > .btn-group {
    border-left: none;
    background-color: @brand-grey4;
}

.action-row-noflex {
    margin-bottom: 50px;
}

.action-row {
    display: flex;
    flex: 1 0 100%;
    margin-bottom: 50px;

    .item-action {
        margin-top: -3px;
        flex: 1;
    }

    @media (max-width:350px) {
        & {
            flex-direction: column;
        }

        .item-action {
            margin-top: 30px;
        }
    }
}

/*-----------------------------------------------------------------------------------*/
/*	9: REPORTS & STATEMENTS
/*-----------------------------------------------------------------------------------*/
.reports {
    th {
        position: relative;
    }

    .os-date {
        width: 70%;
    }

    .os-view {
        width: 15%;
        min-width: 150px;
    }

    .os-creditor-invoices {
        width: 15%;
        min-width: 150px;
    }
}



/*-----------------------------------------------------------------------------------*/
/*	10: SIDEBAR NAVIGATIN FOR MOBILE
/*-----------------------------------------------------------------------------------*/

/*!
 * Start Bootstrap - Simple Sidebar HTML Template (http://startbootstrap.com)
 * Code licensed under the Apache License v2.0.
 * For details, see http://www.apache.org/licenses/LICENSE-2.0.
 */

/* Toggle Styles */

#wrapper {
    padding-left: 0;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

#wrapper.toggled {
    padding-left: 250px;
}

#sidebar-wrapper {
    z-index: 1000;
    position: fixed;
    left: 0; // left 250px for transition in
    width: 250px;
    height: 100%;
    margin-left: -280px;
    overflow-y: auto;
    background: #FFF;
    background-repeat: repeat;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    box-shadow: rgba(0,0,0,0.3) 5px 0px 20px;
}

.owner-style.sidebar-underscore {
    height: 7px;
    background: @brand-blue3;
    border: none !important;
}

.tenant-style.sidebar-underscore {
    height: 7px;
    background: @brand-blue3;
    border: none !important;
}

#wrapper.toggled #sidebar-wrapper {
    width: 250px;
}

#page-content-wrapper {
    width: 100%;
    padding: 15px;
}

.showsidebar {
    left: 280px !important; // sidebar transitions out
}

/* Sidebar Styles */

.sidebar-nav {
    position: absolute;
    top: 0;
    width: 250px;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
        text-indent: 20px;
        line-height: 50px;
        border-bottom: 1px solid @brand-grey5;

        i {
            color: #000;
            display: inline;
            font-size: 14px;
        }

        a {
            display: block;
            text-decoration: none;
            color: #000;
            font-weight: 600;
        }

        a:hover {
            text-decoration: none;
            color: #000;
            background: rgba(255,255,255,1);
            text-indent: 30px;
        }

        a:active, .sidebar-nav li a:focus {
            text-decoration: none;
        }

        &.subsection {
            text-transform: uppercase;
            font-size: 19px;
            font-weight: 700;
            color: #000;
            background-color: @brand-grey5;
        }

        &:first-child {
            border-bottom: none;
        }
    }
}

.owner-style.sidebar-nav > .sidebar-brand {
    height: 45px;
    font-size: 18px;
    line-height: 46px;
    color: #fff;
    background: @brand-blue;
    font-weight: 700;
}

.tenant-style.sidebar-nav > .sidebar-brand {
    height: 45px;
    font-size: 18px;
    line-height: 46px;
    color: #fff;
    background: @brand-yellow;
    font-weight: 700;
}

/*-----------------------------------------------------------------------------------*/
/*	11: MODAL WINDOWS
/*-----------------------------------------------------------------------------------*/
.modal-open {
    overflow: scroll !important;
}

.modal-content {
    border: 0px solid #999;
    border: 0px solid rgba(0,0,0,.2);
    border-radius: 0px;
}

.modal-content {
    h3 {
        font-size: 32px;
        padding: 12px 15px 0;
    }

    .modal-body {
        padding: 30px;
    }
}

.modal-container, .modal-content {

    .modal-header {
        font-size: 24px;
        height: 72px;
        background-color: @brand-grey1;
        color: #000;
        min-height: 24px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 15px 15px 30px;

        &:before, &:after {
            content: none;
        }

        &.owner-style {
            background-color: @brand-blue;
            color: #fff;
        }

        &.tenant-style {
            background-color: @brand-blue;
            color: #fff;
        }

        &.homepage-style {
            background-color: @brand-blue;
            color: #fff;
        }
    }

    .modal-footer {
        height: 60px;
        padding: 12px 30px;
        background: #efefef;

        #confirmBtn-0 {
            float: left;
            background: none;
            color: @brand-blue;
            font-weight: 700;
        }
    }

    .modal-cancel {
        position: relative;
        top: 8px;
        color: @brand-black;
    }
}


/*-----------------------------------------------------------------------------------*/
/*  12: SCHEDULED MAINTENANCE
/*-----------------------------------------------------------------------------------*/
.maintenance-page {
    .icon-logo {
        font-size: 70px;
    }

    h1.header {
        font-size: 50px;
        margin: 10px 0 15px;
    }

    .masthead {
        background: #f8b259;
        padding: 27px 0px;

        h2 {
            color: #fff;
            font-size: 35px;
        }
    }

    .vidsection {
        position: relative;
        width: 688px;
        height: 409px;
        margin: 0 auto;
        overflow: hidden;

        video {
            position: absolute;
            top: 0;
            left: -2px;
            margin: 30px auto;
            width: 692px;
            height: auto;
            display: block;
        }
    }

    .holder {
        margin-top: 110px;
    }

    .img-responsive {
        margin: 0 auto;
    }
}



/*-----------------------------------------------------------------------------------*/
/*  13: INSPECTIONS
/*-----------------------------------------------------------------------------------*/
.flow-wrapper {
    background-color:#efefef;
    margin:-20px -5px 10px;
    padding:20px;
}

.flow-steps {
    text-align: center;
    width: 100%;
}

.btn-spinner {
    position: relative;
    left: -20px;
}

.glyphicon-chevron-down {
    position: relative;
    left: -1px;
}

.inspection-length {
    margin: -15px 0 0 0;
    padding: 0;
    white-space: nowrap;
    font-weight: 600;
    margin: 10px 0 0 0;
}


/*-----------------------------------------------------------------------------------*/
/*	-- MEDIA QUERIES --
/*-----------------------------------------------------------------------------------*/
@media (max-width: 950px) {
    .login {
        .tagline {
            font-size: 45px;
            letter-spacing: -1px;
        }
    }
}

@media (max-width: 767px) {

    #ngProgress {
        background-color: #fff !important;
    }

    .tagline-top {
        display: none;
    }

    .login .last-loggedIn {
        font-size: 14px;
    }

    .login {
        .tagline {
            font-size: 30px;
            letter-spacing: -1px;
            color: #fff;
        }
    }

    .login .nav {
        float: none;

        > li > a {
            font-size: 14px;
        }
    }

    .login {
        .mastfoot {
            position: relative;
        }

        .invited {
            margin: 50px auto 0;
            width: 450px;

            > p.intro {
                font-weight: 600;
                font-size: 16px;
            }
        }
    }

    .mastfoot div {
        text-align: center;
    }

    .footer-container {
        .logos {
            padding: 30px 0 0;

            img {
                margin: 0;
                padding: 0;
            }
        }
    }

    .device-sm {
        display: none;
    }

    .device-md {
        display: block;
    }

    .device-xs {
        display: block;
    }

    .device-lg {
        display: none;
    }

    .owner-style.tabnav {
        border: 0px;
    }

    .tenant-style.tabnav {
        border: 0px;
    }

    .navbar {
        border: 0;
        height: 55px;

        i {
            .transition-all(0.3s);
        }

        .navbar-nav {
            height: 32px;
        }

        .site-logo {
            color: #fff;
        }

        .owner-style.nav-wrapper {
            background: @brand-blue;
            border: none;
        }

        .tenant-style.nav-wrapper {
            background: @brand-blue;
            border: none;
        }

        .owner-style.greeting-wrapper {
            background: @brand-blue3;
        }

        .tenant-style.greeting-wrapper {
            background: @brand-blue3;
        }

        .greeting {
            background-color: transparent !important;
            color: #fff !important;
            font-weight: 600 !important;
            font-size: 14px !important;
            letter-spacing: 0px;
        }

        .dropdown {
            z-index: 99;

            .dropdown-menu {
                list-style: none;
                padding: 0;
                font-size: 14px;
                text-align: left;
                background-color: #fff;
                border: 1px solid rgba(0,0,0,.15);
                -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
                box-shadow: 0 1px 6px rgba(0,0,0,.175);

                > li > a {
                    color: #000 !important;
                    padding: 8px 8px 12px;

                    &:hover {
                        text-decoration: none !important;
                        color: #000 !important;
                        background-color: @brand-grey4 !important;
                    }

                    i {
                        font-size: 24px;
                    }
                }
            }
        }



        .nav > li {
            margin-right: 0px;
            margin-left: 0px;
            padding-top: 7px;

            > a {
                color: #fff;
                font-size: 12px;
                font-weight: 700;

                i {
                    color: #fff;
                }
            }

            > span {
                float: right !important;
                margin-right: 35px;

                > i {
                    margin-top: 10px;
                    font-size: 24px;
                }
            }
        }

        .navbar-header > button {
            margin-left: 15px;
            float: left;
        }

        button.navbar-toggle {
            padding: 17px 0 0;
            border-radius: 0;
            border: none;

            .icon-bar {
                height: 3px;
                background-color: #fff;
                .transition-all(0.3s);
            }
        }

        .navbar-toggle:focus, .navbar-toggle:hover {
            background: transparent;

            .icon-bar {
                background-color: #000;
            }
        }
    }

    .toggle-menu {
        background: transparent !important;

        .icon-bar {
            background-color: #fff !important;
        }
    }

    .snapshot {
        .ss-details {
            position: relative;
            top: -18px;
        }

        .circle {
            width: 40px;
            height: 40px;
        }

        .score-text {
            height: 14px;
            line-height: 14px;
            letter-spacing: -1px;
        }
    }

    .search-contacts {
        .search-input {
            input {
                width: 100%;
            }
        }
    }

    .thread-box-header {
        display: table-cell;
    }

    .navbar-collapse {
        position: absolute;
        z-index: 2;
        background: @brand-blue;
    }

    .h1, h1 {
        font-size: 18px;
        letter-spacing: -1px;
        font-weight: 700;
    }

    .h2, h2 {
        font-size: 14px;
        letter-spacing: 0px;
        font-weight: 700;
    }

    .page-content {
        padding: 25px 15px 0;
        .nav-tabs > li.active > a, .nav-tabs > li.active > a:focus, .nav-tabs > li.active > a:hover {
            font-size: 16px !important;
        }

        .nav-tabs > li > a {
            font-size: 16px !important;
        }
    }

    .social-team {
        padding: 40px 0 30px;
    }

    .compose-label {
        width: 100%;
    }

    .dropdown-menu {
        li a {
            padding-right: 20px;
        }
    }

    .inspection-length {
        clear: both;
    }
}

@media (max-width: 480px) {
    .visible-xxs-block, .visible-xxs {
        display: block;
    }

    .hidden-xxs-block, .hidden-xxs-block {
        display: none;
    }

    .device-sm {
        display: block;
    }

    .device-md {
        display: none !important;
    }

    .device-xs {
        display: block;
    }

    .device-lg {
        display: none !important;
    }

    div.card {
        background-color: #fff;
    }

    div.card div.card-header {
        padding: 8px 15px 0;
        line-height: 24px;
        color: #fff;
        min-height: 24px;
        font-size: 18px;
        font-weight: 600;

        span {
            color: #333;
            font-weight: 700;
        }
    }

    div.card div.card-header.owner-style {
        background-color: @brand-grey1;
    }

    div.card div.card-header.tenant-style {
        background-color: @brand-grey1;
    }

    div.card div.card-content {
        position: relative;
    }

    div.card-gray {
        background-color: #fff;
        border: 4px solid @brand-grey3;
        padding: 5px;
    }

    div.card-gray div.card-gray-header {
        padding: 7px;
        line-height: 24px;
        color: #000;
        min-height: 24px;
        font-size: 16px;
        font-weight: 600;
    }

    div.card-gray div.card-gray-content {
        padding: 10px;
        position: relative;
    }


    .snapshot {
        background-color: #fff;
        padding: 20px 15px 5px;
        margin: -10px -15px;
        font-size: 18px;
        font-weight: 600;
        border-bottom: 1px solid @brand-grey3;
    }


    .snapshot-generic {
        background-color: rgba(211, 211, 211, 0.24);
        padding: 15px 15px 25px;
        margin: -10px -15px;
        font-size: 18px;
        font-weight: 600;
        border-bottom: 1px solid @brand-grey3;
    }

    .pill-wrapper {
        padding: 2px 18px;
        border-radius: 20px;
        font-weight: 600;
        font-size: 14px;
        text-transform: uppercase;
        white-space: nowrap;
    }

    .pill-wrapper-text {
        font-size: 16px;
        font-weight: 700;
        position: relative;
        top: 0px;
    }

    .text-jumbo {
        font-size: 16px;
        font-weight: 700;
        letter-spacing: -1px;
    }

    .accordion {
        margin: 0px -9px;
    }

    .accordion1 {
        margin: 0px -9px;
    }

    .navbar {
        height: 75px;

        .nav > li {
            margin-right: 10px;
            margin-left: 0px;

            > a {
                color: #fff;
                font-size: 16px;
                font-weight: 500;

                > i {
                    color: #fff;
                }
            }

            > span {
                float: right !important;
                margin-right: 35px;

                > i {
                    margin-top: 10px;
                    font-size: 24px;
                }
            }
        }

        .greeting-wrapper {
            padding: 5px 15px;
            height: 30px;

            ul li {
                margin: -2px 0 0 0;
            }

            i {
                font-size: 12px;
            }
        }

        .navbar-header > button {
            margin-left: 15px;
            float: left;
        }

        .dropdown-toggle {
            font-weight: 600;
            color: #fff;
        }
    }

    .navbar-collapse {
        position: absolute;
        z-index: 2;
    }

    #alertFlash {
        .alert {
            height: 70px; //
        }

        .alert-table {
            height: 70px;
        }

        .icon-container {
            width: 70px; //
            height: 70px; //
            font-size: 35px; //
            margin: 0 20px 0 0;
            padding: 10px 0 0;
        }

        .close {
            margin-top: 20px;
            margin-right: -5px;
        }
    }

    .btn.primary {
        padding: 0 14px;
    }

    .no-text-td {
        height: 120px;
        text-align: left;
        padding: 50px !important;
    }

    .modal-container {
        .modal-cancel {
            display: none;
        }

        .modal-header {
            font-size: 22px;
        }
    }

    .modal-header {
        h3 {
            font-size: 22px;
            padding: 20px 15px 0;
        }
    }

    .maintenance-page {
        .holder {
            margin-top: 60px;
        }
    }

    .form-label {
        font-size: 14px;
        float: none;
        clear: both;
        margin: 0 0 10px 0;
    }

    .dropdown {
        display: block;
        float: none;
        clear: both;

        .dropdown-toggle {
            display: block;
        }

        &.pull-left {
            width: 100%;
        }
    }

    .dropdown-style {
        width: 100%;
    }

    .dropdown-menu {
        max-width: 400px;
    }

    .nav > li > a {
        padding: 12px;
    }

    .anchor-btn-primary {
        background-color: @brand-blue;
        color: white !important;
        padding: 5px 18px;
        border-radius: 20px;
        font-weight: 400;
        font-size: 12px;

        span {
            color: white !important;
        }
    }

    .time-picker-input {
        width: 80px;
    }

    .mce-tinymce .mce-container-body .mce-last.mce-flow-layout-item.mce-btn-group {
        display: none;
    }
}

.data-label {
    font-weight: 700;
    padding-top: 10px;
    padding-bottom: 10px;
}

.data-value {
    font-weight: normal;
    padding-top: 10px;
    padding-bottom: 10px;
}

.sub-header {
    font-weight: 700;
    border-bottom: solid 1px @brand-grey3;
    margin-bottom: 20px;
    margin-top: 10px;
}

hr {
    margin: 5px 0;
}

hr.no-style {
    padding: 0;
    margin: 0;
}

.empty-state {
    font-size: 16px;
    text-align: center;
    padding: 20px;
    font-weight: bold;
    color: #444;
}

.notification-info {
    margin: 0 0 20px 0;
}

.text-info {
    color: @state-info-text;
}


.btn-info.active, .btn-info:active, .btn-info:hover, .open>.dropdown-toggle.btn-info {
   background-color: @brand-blue;
       border-color: #fff;
       > span.text-info {
           color: #fff !important;
       }
}

.btn-info.active.focus, .btn-info.active:focus, .btn-info.active:hover, .btn-info:active.focus, .btn-info:active:focus, .btn-info:active:hover, .open>.dropdown-toggle.btn-info.focus, .open>.dropdown-toggle.btn-info:focus, .open>.dropdown-toggle.btn-info:hover {
   background-color: @brand-blue;
       border-color: #fff;
       > span.text-info {
           color: #fff !important;
       }
}

.dropdown2 {
    display: flex !important;
    align-items: center;
    background-color: #E9E9E9 !important;
    height: 24px;
    font-weight: 600;
    padding: 0 10px !important;
    border-radius: 12px;
    border: none;
    color: #464B4F !important;
    font-size: 12px;
    margin-right: 4px;
}

.navbar-brand {
    padding: 0 15px;
}

.header4 {
    display: flex;
    padding-top: 15px;
    align-items: baseline;
}

.container {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px;
}

.align-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.icon-close-dialog {
    font-size: 12px;
}

@import "components/index";
