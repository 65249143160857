@import "../variables.less";

.td-uploader {
}

.td-upl-preview-item {
    padding:10px;

    span {
        color: @brand-black;
    }

    .remove {
        padding-left: 10px;
        color: @state-danger-text;
    }

    .media-thumbnail {
        max-width: 50px;
        height: auto;
        width: 50px;
    }

    .fileSize {
        color:#aaa;
    }
}
