.td-MRViewPanel {
    .content  {
        height: 80px;
        max-height: 80px;
        overflow:auto;
        margin:5px 0 0;
    }
}

.td-MRDesc {
    height:240px !important;
    resize: none;
}

.td-approval{
    position:absolute; 
    z-index:100; 
    top:40px; 
    right:25px;
}