@import "../variables.less";

.box-card {
    background-color: #fff;
    margin-bottom: 50px;
    overflow:hidden;

    .card-header {
        padding: 5px 15px;
        background-color: @brand-beige;
        line-height: 24px; 
        min-height: 24px;
        font-size: 20px;
        font-weight: 500;
        vertical-align:top;
        white-space:nowrap;
        overflow:hidden;
        text-overflow: ellipsis;

        i {
            font-size: 30px;
            position: relative;
            line-height:24px !important; 
        }

        span {
            position: relative;
            text-transform: capitalize;
        }
    }

    .card-content {
        padding: 10px 15px 30px;
        position: relative;
    }

    .card-footer-bottom {
        padding: 10px !important;
        background-color: #efefef;
    }

    &.card-grey {
        background-color: #fff;
        border: 1px solid #dbdbdb;

        &.box-status-card {
            padding:0;
        }

        .card-header {
            background-color:#dbdbdb;
            line-height: 24px; 
            color: #000;
            min-height: 24px;
            font-size: 20px;
            font-weight: 500;
        }

        .card-content {
            padding: 20px;
            position: relative;
        }
    }

    .card-status-block {
        height: 40px;

        span {
            font-size: 20px;
            text-transform: capitalize;
            color:#fff;
        }
    }

}
