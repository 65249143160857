.option-card{
    text-align: center;
    cursor: pointer;
    margin-bottom:10px;
}

.option-item-wrapper {
    display:table;
    width:100%
}

.option-item{
    border: 4px solid @gray-lightest;
    height: 110px;
    position:relative;
    padding:0 20px;
    display:table-cell;
    vertical-align: middle;
    @media (max-width:481px)  {
        padding:0 5px;
        height: 80px;
    }
    > span{
        font-weight: bold;
        vertical-align: middle;
        display: inline-block;
        line-height: normal;
        padding: 15px;
    }
}

.option-item-selected{
    border: 4px solid @brand-green;
}

.radio-style{
    height: 25px;
    width: 25px;
    position:absolute;
    top:0px;
    left:0px;
    background-color: @gray-lightest;
}

.radio-style-selected{
    background-color: @brand-green;
}

.inner-circle {
   background: #fff;
   border-radius: 50%;
   height: 10px;
   width: 10px;
   position:relative;
   top:5px;
   left:5px;
 }