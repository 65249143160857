.td-file-add {
    margin-bottom: 20px;

    .dd-area {
        border:solid 1px @brand-grey3;
        height: 240px;
        padding:70px 15px 0;
        text-align: center;
        cursor:pointer;
        font-weight:700;
        color:@brand-grey3;

        &.hover {
            border: dashed 2px @brand-black;
            background-color: @gray-lightest;
        }

        img {
            width:60px;
            height: auto;
            display: block;
            margin:0 auto;
        }

        .dd-info {
            color:@brand-grey3;
            text-transform: uppercase;
        }
    }
}